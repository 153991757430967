import React from "react";

export const SportsBetActivityBox = ({ title, data, type, total }) => {
  return (
    <div className="box-activity rounded-xl relative h-[220px]">
      <div className="top-box px-6 py-5 pb-3">
        <h1>{title}</h1>
      </div>
      <div className="content-area px-4 mb-3 mt-2">
        {type === "daily" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Total Bet Amount</p>
              <p>${data?.totalBetAmount ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Payout</p>
              <p>${data?.totalPayout ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Platform Fees</p>
              <p>${data?.totalPlatformFees ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "weekly" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Total Bet Amount</p>
              <p>${data?.totalBetAmount ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Payout</p>
              <p>${data?.totalPayout ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Platform Fees</p>
              <p>${data?.totalPlatformFees ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "monthly" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Total Bet Amount</p>
              <p>{data?.totalBetAmount ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Payout</p>
              <p>${data?.totalPayout ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Total Platform Fees</p>
              <p>${data?.totalPlatformFees ?? "0.00"}</p>
            </div>
          </>
        )}
      </div>
      <div className="bottom-box rounded-bl-xl rounded-br-xl h-11 px-4 flex items-center justify-between absolute bottom-0 w-full ">
        <h1>Total</h1>
        {type === "daily" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "weekly" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "monthly" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
      </div>
    </div>
  );
};
