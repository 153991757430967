import React from "react";
import { Link } from "react-router-dom";
import receiveBtn from "../../assets/img/admin/svg/receive-btn.svg";
import sendBtn from "../../assets/img/admin/svg/send-btn.svg";
import formatCurrencyName, { formatBalance } from "../../utils/wallet";

export const CryptoWalletTable = ({ wallets }) => {
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>Crypto</th>
          <th>Balance</th>
          <th>Action</th>
        </thead>
        <tbody>
          {wallets?.map((wallet) => (
            <tr key={wallet?._id} className="text-[#aeb2b1]">
              <td>{formatCurrencyName(wallet?.currency)}</td>
              <td>{formatBalance(wallet?.balance, 6)}</td>
              <td>
                <div className="flex items-center gap-[10px]">
                  <Link
                    to={`/admin/wallets/deposit/${wallet?.currency}`}
                    className="bg-[#6FCF17] text-[#111010] flex items-center h-[22px] w-[71px] rounded-[2px] justify-center gap-1"
                  >
                    <img src={sendBtn} alt="" />
                    Deposit
                  </Link>
                  <Link
                    to={{
                      pathname: "/admin/wallets/withdraw",
                      state: {
                        walletAddress: wallet?.address,
                        balance: wallet?.balance,
                      },
                    }}
                    className="bg-[#6FCF17] text-[#111010] flex items-center h-[22px] w-[75px] rounded-[2px] justify-center gap-1"
                  >
                    <img src={receiveBtn} alt="" />
                    Withdraw
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
