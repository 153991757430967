import React, { useState, useEffect } from "react";
import expand from "../../assets/img/sports/expand.svg";
import collapse from "../../assets/img/sports/collapse.svg";
import { BetTransactionTable } from "../../pages/admin/BetTransactionTable";
import {
  fetchSportsBetCategory,
  fetchSportsEvents,
  fetchSportsBetTransactions,
} from "../../queries/gaming";

export const LobbySports = ({ sportName, type }) => {
  const [sportsCategoryData, setSportsCategoryData] = useState(null);
  const [, setEvents] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [activepagination, setactivepagination] = useState(1);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    let canceled = false;

    const fetchData = async () => {
      try {
        const { data: sportsData } = await fetchSportsBetCategory(sportName);
        if (!canceled) {
          setSportsCategoryData(sportsData);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    if (sportName) {
      fetchData();
    }

    return () => {
      canceled = true;
    };
  }, [sportName]);

  useEffect(() => {
    if (sportsCategoryData) {
      const keys = sportsCategoryData.map((obj) => obj.key);
      const fetchData = async () => {
        try {
          const { data } = await fetchSportsEvents(keys);
          setEvents(data);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchData();


      const fetchTransactions = async () => {
        try {
          const { data } = await fetchSportsBetTransactions(keys, type);
          setTransactions(data);
        } catch (error) {
          console.error(error.message)
        }
      }
      fetchTransactions();

      setExpandedItems(new Array(sportsCategoryData.length).fill(false));
    }
  }, [sportsCategoryData, type]);

  const toggleCollapse = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const handlePageChange = (newPage) => {
    setactivepagination(newPage);
  };

  return (
    <>
      <div className="flex justify-between gap-2">
        <div className="flex flex-col w-full">
          {sportsCategoryData &&
            sportsCategoryData.map((sport, index) => (
              <div className="bg-[#1A1A1B] p-6 rounded-lg mt-4" key={index}>
                <div className="flex justify-between">
                  <h1 className="text-[14px] text-[#fff] font-arabicbold">
                    {sport.title}
                  </h1>
                  <button onClick={() => toggleCollapse(index)}>
                    {expandedItems[index] ? (
                      <img src={collapse} alt="collapse" />
                    ) : (
                      <img src={expand} alt="expand" />
                    )}
                  </button>
                </div>
                {expandedItems[index] && (
                  <>
                    <div className="table-wrapper">
                      {/* {isLoading ? (
                      <>
                        <ButtonLoader />
                      </>
                    ) : (
                      <> */}
                      <BetTransactionTable
                        // totalPages={data?.data?.totalPages}
                        // fetchData={refetch}
                        data={transactions[index]}
                        activepagination={activepagination}
                        onPageChange={handlePageChange}
                        type={type}
                      />
                      {/* </>
                    )} */}
                    </div>
                    {/* <div className="py-6 border-t border-[#2E2E2E] mt-6 text-[12px] text-white">
                      No transactions yet
                    </div> */}
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default LobbySports;
