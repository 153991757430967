import React, { useState } from "react";
import { formatDateToTimestamp } from "../../utils/DateFormat";
import Prev from "../../assets/img/table-prev.svg"
import Next from "../../assets/img/table-next.svg";

export const TransactionTable = ({ data, onPageChange }) => {
  const [activepagination, setActivePagination] = useState(1);

  const handlePaginationClick = (pageNumber) => {
    if (pageNumber === "prev" && activepagination > 1) {
      onPageChange(activepagination - 1);
      setActivePagination(activepagination - 1);
    } else if (pageNumber === "next" && activepagination < 15) {
      onPageChange(activepagination + 1);
      setActivePagination(activepagination + 1);
    } else {
      onPageChange(pageNumber);
      setActivePagination(pageNumber);
    }
  };

  // console.log(data?.totalPages);
  // console.log(data);
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>TxID</th>
          <th>Email</th>
          <th>Type</th>
          {/* <th>Currency</th> */}
          <th>Date</th>
          <th>Amount</th>
          <th>Note</th>
          <th> Others Information</th>
          <th>Status</th>
        </thead>
        <tbody>
          {data?.transactions?.map((row) => (
            <tr>
              <td className="text-[#aeb2b1]">{row?._id}</td>
              <td className="px-3 text-[#aeb2b1]">{row?.userId?.email}</td>
              <td className="px-3 text-[#aeb2b1]">
                {row?.flag?.toUpperCase()}
              </td>
              {/* <td>{row?.currency}</td> */}
              <td className="text-[#aeb2b1]">
                {formatDateToTimestamp(row?.createdAt)}
              </td>
              <td className="px-3 text-[#aeb2b1]">
                <span
                  className={`${
                    row?.transactionType === "debit"
                      ? "text-red-400"
                      : "text-green-500"
                  }`}
                >
                  {row?.transactionType === "debit" ? "-" : "+"}{" "}
                  {parseFloat(row?.amount)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {row?.currency}
                </span>{" "}
                <br />
                {!isNaN(parseFloat(row?.meta?.paymentCurrencyAmount)) && (
                  <>
                    {row?.meta?.paymentCurrencyAmount && <>≈</>}
                    {parseFloat(row?.meta?.paymentCurrencyAmount)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {row?.meta?.paymentCurrency}
                  </>
                )}
              </td>
              <td className="text-[#aeb2b1]">{row?.note}</td>
              <td className="text-[#aeb2b1]">
                {row?.meta?.fiatAmount && (
                  <>
                    <p>Fiat Amount : {row?.meta?.fiatAmount}</p>
                  </>
                )}
                {row?.meta?.paymentCurrency && (
                  <>
                    <p>Payment Currency : {row?.meta?.paymentCurrency}</p>
                  </>
                )}
                {row?.meta?.fiatPaymentId && (
                  <>
                    <p>Fiat Payment ID : {row?.meta?.fiatPaymentId}</p>
                  </>
                )}
                {row?.meta?.stakingSubscriptionId && (
                  <>
                    <p>Subscription Id : {row?.meta?.stakingSubscriptionId}</p>
                  </>
                )}
                {row?.meta?.paidDate && (
                  <>
                    <p>
                      Paid Date : {formatDateToTimestamp(row?.meta?.paidDate)}
                    </p>
                  </>
                )}
                {row?.meta?.paymentMethod && (
                  <>
                    <p>Payment Method : {row?.meta?.paymentMethod}</p>
                  </>
                )}
                {row?.meta?.paymentCurrencyAmount && (
                  <>
                    <p>
                      Currency Amount :{" "}
                      {parseFloat(row?.meta?.paymentCurrencyAmount).toFixed(4)}
                    </p>
                  </>
                )}
                {row?.meta?.walletAddress && (
                  <>
                    <p>Wallet Address : {row?.meta?.walletAddress}</p>
                  </>
                )}
                {row?.meta?.paymentCurrency && (
                  <>
                    <p>Payment Currency : {row?.meta?.paymentCurrency}</p>
                  </>
                )}
                {row?.meta?.transactionType && (
                  <>
                    <p>Transaction Type : {row?.meta?.transactionType}</p>
                  </>
                )}
              </td>
              <td className="px-3 text-[#aeb2b1]">
                {row?.status?.toUpperCase()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex pagination-table items-center justify-center mt-8">
        <button
          onClick={() => handlePaginationClick("prev")}
          className="flex items-center mr-2"
        >
          <img src={Prev} alt="previous button" />

          <p>Previous</p>
        </button>
        {[...Array(data?.totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`rounded count py-2 px-3 flex justify-between items-center ${
              activepagination === index + 1 && "active"
            }`}
            onClick={() => handlePaginationClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePaginationClick("next")}
          className="flex active items-center ml-2"
        >
          <p>Next</p>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <img src={Next} alt="Next button" />
          </svg>
        </button>
      </div>
    </>
  );
};
