import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

import { File } from "lucide-react";
import { GrAttachment } from "react-icons/gr";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import copy from "../../assets/img/p2p/copy.svg";
import profile from "../../assets/img/p2p/profile.svg";
import send from "../../assets/img/send.svg";
import notificationSound from '../../assets/sound/message_sound.mp3';
import ButtonLoader from '../../components/ButtonLoader';
import { privateRequest } from "../../config/axios.config";
import { useAuth } from "../../contexts/AuthContext";
import { useOrderDetails } from "../../queries/p2pAds";
import { dateFormatter } from '../../utils';

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL || "http://localhost:3000";
const SOCKET_PATH = "/user/socket/chat";


export const DisputeHistoryView = () => {
  const { user } = useAuth();
  const [transactionId, setTransactionId] = useState(null);
  const [delpopup, setdelpopup] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [dropDown, setdropDown] = useState("Click to expand");
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const [paymentDropdownVisible, setPaymentDropdownVisible] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState("");
  const attachmentInput = useRef("attachmentInput");
  const [attachmentPreview, setAttachmentPreview] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  const { data: result, refetch, isLoading } = useOrderDetails(params.id);

  const orderDetails = result?.data;


  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [appealReason, setAppealReason] = useState("");
  const [releaseTo, setReleaseTo] = useState('');
  const currentUserId = user?._id;
  const audio = new Audio(notificationSound);

  let remarks = {};
  if (orderDetails?.remarks) {
    remarks = JSON?.parse(orderDetails?.remarks);
  }



  useEffect(() => {
    refetch();
  }, [refetch]);

  // Function to toggle visibility of user dropdown
  const toggleUserDropdown = () => {
    setUserDropdownVisible(!userDropdownVisible);
    setDropdownText();
  };
  const setDropdownText = () => {
    setdropDown("");
  }
  // Function to toggle visibility of payment methods dropdown
  const togglePaymentDropdown = () => {
    setPaymentDropdownVisible(!paymentDropdownVisible);
  };

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success("Text copied");
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const getMessages = async () => {
    try {
      const res = await privateRequest.get(
        `admin/chat/getMessages/${params.id}`
      );
      setMessages(res?.data?.data?.docs);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
  };

  const sendMessage = async () => {
    if (!message) return; // Prevent sending empty messages

    try {
      const sentMessage = {
        senderType: "admin",
        sender: {
          _id: currentUserId,
        },
        attachment: "",
        content: message,
        conversation: params.id,
        createdAt: new Date().toISOString(),
      };

      const form = new FormData();
      form.append('refId', params.id);
      form.append('message', message);

      await privateRequest.post('admin/chat/sendMessage', form);

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setMessage("");
      setAttachment(""); // Clear the attachment after sending the message
    } catch (error) {
      console.log(error);
    }
  };

  const sendAttachment = async () => {
    try {
      let attachmentUrl = null;
      if (attachment) {
        attachmentUrl = URL.createObjectURL(attachment);
      }

      const sentMessage = {
        senderType: "sender",
        sender: {
          _id: currentUserId,
        },
        content: message,
        attachment: attachmentUrl,
        conversation: params.id,
        createdAt: new Date().toISOString(),
      };

      const form = new FormData();
      if (attachment) {
        form.append('attachment', attachment);
      }
      form.append('refId', params.id);

      const res = await privateRequest.post('user/chat/sendMessage', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setMessage("");
      setAttachment(""); // Clear the attachment after sending the message
      setAttachmentPreview(""); // Clear the preview after sending the message

      console.log("send message ", sentMessage);
    } catch (error) {
      console.log("Error sending message:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const uploadAttachment = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      await sendAttachment();
    }

  };

  const triggerFileInput = () => {
    if (attachmentInput.current) {
      attachmentInput.current.click();
    }
  };



  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    // Update the state with the new value
    setAppealReason(inputValue);
  };


  useEffect(() => {
    getMessages();
    const newSocket = io(SOCKET_SERVER_URL, { path: SOCKET_PATH });

    newSocket.on("connect", () => {
      console.log("Socket connected, joining room:", params.id);
      newSocket.emit("joinRoom", { chatroomId: params.id });
    });

    newSocket.on("newMessage", (message) => {
      console.log("Message : ", message);
      if (message.content.sender !== currentUserId) {
        setMessages((prevMessages) => [...prevMessages, message.content]);
        audio.play().catch(error => console.error('Error playing the sound:', error));
        refetch();
      }
    });

    newSocket.on("orderMessage", (message) => {
      if (message?.messages?.type === 'markAsPaid') {
        toast.success(message?.messages?.paidMessage);
      }
      refetch();
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [params.id]);



  const handleResolveOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisable(true);
    try {
      const orderId = params.id;
      const resolveData = {
        "id": orderId,
        "releaseTo": releaseTo
      }
      const res = await privateRequest.post(
        `admin/p2p/order/resolveOrder`, resolveData
      );
      toast.success("Order resolved successfully!");
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
    setShowModal(false);
    setLoading(false);
    setDisable(false);
  };



  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);


  return (
    <>
      <div>
        <div className="flex items-start home admin-dashboard admin-dashboard-agent">
          <Aside active="8/2" />
          <div className="flex-1">
            <Header heading="History View" />
            <div className="body-area-admin py-8 px-6">
              <div className="top-body-area flex items-center justify-between">
                <h1>Dispute Details</h1>
                <button className="flex items-center" onClick={(e) => { }}>
                  <img src={arrowback} alt="" className="mr-2" />
                  <Link to={`/admin/p2p/resolution-center/history`}>
                    <h1 className="font-arabicreg text-[16px] text-lg text-[#11E311]">Back</h1>
                  </Link>
                </button>
              </div>
              <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
                <div className="body-area flex items-start px-6 pt-6 h-auto">
                  <div className="body-left-area w-full h-full flex flex-col">
                    <div className="flex justify-between gap-4 sm:flex-col h-full">
                      {/* chat boxes */}
                      <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full relative flex flex-col">
                        <div className="flex items-start gap-2 pb-6 border-b border-[#3C403F]">
                          <img src={profile} />
                          <div>
                            <h1 className="text-white text-[18px] font-arabicbold">
                              {user?.email}
                            </h1>
                            <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                              ID - {user?._id}
                            </p>
                          </div>
                        </div>

                        {/* conversation part */}
                        <div
                          ref={chatContainerRef}
                          className="flex flex-col gap-2 pb-6 mt-3 h-96 overflow-y-scroll pr-2 mb-10 flex-grow">
                          {messages?.map((msg, index) => (
                            <div
                              key={index}
                              className={`flex ${msg?.senderType === 'admin'
                                ? "justify-end"
                                : "justify-start"
                                } w-full`}
                            >
                              <div className="flex flex-col w-2/3">
                                <div className="flex items-center">
                                  {msg?.senderType === 'admin' ? (
                                    <>
                                      <span class="text-xs text-gray-700">
                                        You
                                      </span>
                                    </>
                                  ) : <>
                                    <img src={profile} className="w-12 h-12 pr-4" />
                                  </>}
                                  {msg?.senderType !== 'admin' && <>
                                    <h4 className="text-sm text-white">
                                      {msg?.sender?.email}
                                    </h4>
                                  </>}
                                </div>
                                <div className="attachment">
                                  {msg?.attachment[0] && <>
                                    {isImage(msg?.attachment[0]) ? <>
                                      {msg?.attachment[0] && <>
                                        <PhotoProvider>
                                          <PhotoView src={msg?.attachment[0]}>
                                            <img src={msg?.attachment[0]} className="w-full h-40 cursor-pointer object-cover rounded-md" />
                                          </PhotoView>
                                        </PhotoProvider>
                                      </>}
                                    </> : <>
                                      <a
                                        href={msg?.attachment[0]}
                                        download
                                        className="w-full cursor-pointer object-cover rounded-md bg-[#49db69] py-3 flex items-center justify-center text-center"
                                      >
                                        <span className="flex items-center gap-2"><File className="text-white" /> <span className="text-white">file{getFileExtension(msg?.attachment[0])}</span></span>
                                      </a>
                                    </>}
                                  </>}
                                </div>
                                {msg?.content && <>
                                  <div className="p-4 mt-1 bg-[#252525] rounded-t-lg rounded-br-xl">
                                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg">
                                      {msg?.content}
                                    </p>
                                  </div>
                                </>}
                                {msg?.senderType !== 'admin' && (
                                  <>
                                    <span class="text-xs text-gray-700">
                                      {dateFormatter(msg?.createdAt)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="message-area flex items-center px-4 h-14 rounded-lg border border-[#3C403F]">
                          <input
                            type="text"
                            placeholder="Send a message..."
                            className="flex-1 w-full bg-transparent focus:outline-none text-white"
                            value={message}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                          />
                          <div className="flex items-center justify-between gap-2">
                            <input
                              type="file"
                              onChange={uploadAttachment}
                              className="hidden" // Hide the input
                              ref={attachmentInput}

                            />
                            <GrAttachment className="text-white cursor-pointer"
                              onClick={triggerFileInput} />

                            <img
                              src={send}
                              alt="send"
                              className="cursor-pointer"
                              onClick={sendMessage}
                            />
                          </div>
                        </div>
                      </div>
                      {/* chat box end */}

                      <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                        <div className="bg-white bg-opacity-5 p-3 rounded">
                          <h2 className="text-red-600 text-[20px] font-arabicbold mb-4">Dispute Reason</h2>
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex justify-between">
                            <span>Reason :  {remarks?.reason}</span>
                          </p>
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex justify-between">
                            <span>Details :  {remarks?.details}</span>
                          </p>
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex justify-between">
                            <span>Attachment :  <img src={remarks?.proof} className="w-full h-44 object-cover rounded-md" alt="" /></span>
                          </p>
                        </div>
                        <div>
                          <h3 className="text-white py-5 text-lg font-extrabold">Order Details</h3>
                        </div>
                        <h1 className="text-white flex justify-between text-[20px] font-arabicbold mb-4">
                          {orderDetails?.advertisement.adsType === 'to_buy' ? <>
                            Buy
                          </> : <>
                            Sell
                          </>} {orderDetails?.advertisement.cryptoCurrency}
                        </h1>
                        <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                            Order Number:{" "}
                            <span className="text-white">{orderDetails?._id}</span>
                            <img
                              src={copy}
                              className="cursor-pointer"
                              onClick={() => handleCopyClick(orderDetails?._id)}
                            />
                          </p>
                        </div>
                        <div className="flex justify-between py-6 border-b border-[#3C403F]">
                          <div>
                            <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                              Amount
                            </p>
                            <h1 className="text-white text-[16px] font-arabicbold">
                              {orderDetails?.fiatAmount}{" "}
                              {orderDetails?.advertisement?.fiatCurrency}
                            </h1>
                          </div>
                          <div>
                            <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                              Price per {orderDetails?.advertisement?.cryptoCurrency}
                            </p>
                            <h1 className="text-white text-[16px] font-arabicbold">
                              {orderDetails?.exchangeRate}{" "}
                              {orderDetails?.advertisement?.fiatCurrency}
                            </h1>
                          </div>
                          <div>
                            <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                              Quantity
                            </p>
                            <h1 className="text-white text-[16px] font-arabicbold">
                              {orderDetails?.amount}{" "}
                              {orderDetails?.advertisement?.cryptoCurrency}
                            </h1>
                          </div>
                        </div>
                        <div className="flex justify-between items-start py-6 border-b border-[#3C403F] sm:flex-col">
                          <div className="w-full">
                            {orderDetails?.isFiatPaid === false ? <>
                              <h1 className="text-white text-[20px] font-arabicbold mb-4">
                                Buyer will transfer funds to this account:
                              </h1>
                            </> : <>
                              <h1 className="text-white text-[20px] font-arabicbold mb-4">
                                Buyer has Transfered the payment
                              </h1>
                            </>}
                            {orderDetails?.paymentMethod?.map((detail, index) => (
                              <div key={index}>
                                <div className="text-center">
                                  <h1 className="text-white text-[18px] font-arabicbold mx-auto">
                                    {detail?.name}
                                  </h1>
                                </div>
                                {detail?.data?.data ? <>
                                  {Object?.entries(detail?.data?.data)?.map(
                                    ([key, value]) => (
                                      <div
                                        key={key}
                                        className="flex justify-between items-center mb-2"
                                      >
                                        <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                                          {key}
                                        </p>
                                        <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                                          {value}
                                          <img
                                            src={copy}
                                            className="ml-2 cursor-pointer"
                                            alt="copy icon"
                                            onClick={() => handleCopyClick(value)}
                                          />
                                        </h1>
                                      </div>
                                    )
                                  )}
                                </> : <>
                                  {orderDetails?.paymentDetails?.map((detail, index) => (
                                    <div key={index}>
                                      {Object?.entries(detail)?.map(([key, value]) => (
                                        <div
                                          key={key}
                                          className="flex justify-between items-center mb-2"
                                        >
                                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                                            {key}
                                          </p>
                                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                                            {value}
                                            <img
                                              src={copy}
                                              className="ml-2 cursor-pointer"
                                              alt="copy icon"
                                              onClick={() => handleCopyClick(value)}
                                            />
                                          </h1>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="mt-5">
                          {orderDetails?.status === 'released' ? <>
                            <h1 className="text-[#6FCF17] text-[18px] font-arabicbold pt-4 w-full text-center mb-3">
                              Order released, trade has completed
                            </h1>
                            {orderDetails?.senderReviewed === false && <>
                              <button
                                className="text-[#282828] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold"
                                onClick={() => {
                                  setShowRateModal(!showRateModal);
                                }}
                              >
                                Give ratings
                              </button>
                            </>}
                          </> : <>
                            {orderDetails?.isFiatPaid === true && <>
                              {orderDetails?.flag === 'releasedToSender' ? <>
                                <p className="text-white">Order Resolved</p>
                              </> : <>
                                <div className="flex justify-between items-center gap-3">
                                  <button
                                    className="text-[#2E2E2E] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold"
                                    onClick={handleConfirmClick}
                                  >
                                    Resolve Order
                                  </button>
                                </div>
                              </>}
                            </>}
                          </>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
            <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
              <h1 className="text-white text-[20px] font-arabicbold">
                Resolved Order
              </h1>
              <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
                Please choese party to release the order
              </p>
              <select value={releaseTo} required onChange={(e) => setReleaseTo(e.target.value)} className="w-full py-2 px-3 rounded bg-[#282828] text-white">
                <option value="">Select Party</option>
                <option value="sender">Sender</option>
                <option value="receiver">Receiver</option>
              </select>
              <div className="flex justify-between gap-4 my-4">
                <button
                  className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={disable}
                  className="text-[#2E2E2E] text-[14px] bg-[#F6465D] rounded-lg py-3 w-full font-arabicbold"
                  onClick={handleResolveOrder}
                >
                  {isLoading ? <>
                    <ButtonLoader />
                  </> : <>
                    Resolve Order
                  </>}
                </button>
              </div>
            </div>
          </div>
        )}
        <Footer />


      </div>
    </>
  );
};


const isImage = (fileUrl) => {
  const imageExtensions = [".png", ".jpeg", ".jpg", ".gif", ".bmp", ".webp"];
  const fileExtension = fileUrl?.slice(fileUrl?.lastIndexOf(".")).toLowerCase();
  return imageExtensions?.includes(fileExtension);
};

const getFileExtension = (fileUrl) => {
  const fileExtension = fileUrl?.slice(fileUrl?.lastIndexOf(".")).toLowerCase();
  return fileExtension;
}