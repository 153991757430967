import React, { useState } from "react";
import Pagination from "../../components/admin/Pagination";
export const MemberBalanceTable = ({
  data,
  fetchData,
  totalPages,
  onPageChange,
  activepagination,
}) => {
  const [delpopup, setdelpopup] = useState(false);
  const [actionType, setActionType] = useState(""); // "block" or "unblock"
  const [userId, setUserId] = useState(null);
  const [rankUpdate, setRankUpdate] = useState(false);
  const [userRank, setUserRank] = useState(null);
  const handleBlock = (id) => {
    setActionType("block");
    setUserId(id);
    setdelpopup(true);
  };

  const handleUnblock = (id) => {
    setActionType("unblock");
    setUserId(id);
    setdelpopup(true);
  };

  const handUserRankUpdate = (id) => {
    setRankUpdate(true);
    setUserId(id);
    console.log(id);
  };

  // const handlePageChange = (page) => {
  //   setactivepagination(page);
  //   fetchData(page);
  //   onPageChange(page);
  // };

  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Email</th>
          <th>Cash Wallet Balance</th>
          <th>GC Balance</th>
        </thead>
        <tbody>
          {data?.map((member, index) => (
            <tr className="text-[#aeb2b1]">
              <td>{index + 1}</td>
              <td>{member.email}</td>
              <td>
                {parseFloat(member.cashWallet)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                {parseFloat(member.gcBalance)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        totalPages={totalPages} // Set the total number of pages
        currentPage={activepagination}
        onPageChange={onPageChange}
      />
    </>
  );
};
