import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";
import ButtonLoader from "../../components/ButtonLoader";
// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";
import search from "../../assets/img/admin/svg/search.svg";
import { MemberTable } from "../../pages/admin/MemberTable";
// utils
import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";

export const MemberList = () => {
  const navigate = useNavigate();
  const [selectedRank, setSelectedRank] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [activepagination, setactivepagination] = useState(1); // State for active page
  const { data, refetch, isLoading } = useQuery(
    ["MemebrListApi", activepagination, selectedRank],
    () =>
      privateRequest.get("admin/member-management/member-list", {
        params: {
          page: activepagination,
          rank: selectedRank,
          email: searchInput,
        },
      })
  );

  const handlePageChange = (newPage) => {
    setactivepagination(newPage);
  };
  const handleSearchFetch = (e) => {
    setSearchInput(e.target.value);
    refetch();
  };
  const handlePasteFetch = (e) => {
    setSearchInput(e.target.value);
    refetch();
  };
  refetch();
  if (searchInput.trim() === "") {
    refetch();
  }
  const Members =
    data?.data?.members?.filter((el) => {
      const emailMatch = el.email
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const rankMatch =
        selectedRank === "" ||
        el.rank?.toLowerCase().includes(selectedRank.toLowerCase());
      const usercode = el.usercode?.includes(searchInput.toLowerCase());
      return emailMatch || rankMatch || usercode;
    }) ?? [];

  const resetFilter = (e) => {
    e.preventDefault();
    setSearchInput("");
    setSelectedRank("");
    refetch();
  };

  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="member" />

        <div className="flex-1">
          <Header heading="Members List" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Members List</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / Members List */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Members List</h1>
              </div>
              <div className="bottom-agent bottom-agent-page   pt-6  pb-4  gap-2 flex items-end">
                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      value={searchInput}
                      onChange={handleSearchFetch}
                      onKeyDown={handleSearchFetch}
                      onPaste={handlePasteFetch}
                      type="text"
                      placeholder="Search email, agent name, first name, last name"
                      className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                    />
                    <img src={arrowdown} alt="" />
                  </div>
                </div>

                <div className="input-wrapper px-3">
                  <select
                    name="ranks"
                    id=""
                    value={selectedRank}
                    onChange={(e) => setSelectedRank(e.target.value)}
                  >
                    <option value="">Select Rank</option>
                    <option value="JuniorAssociate">JuniorAssociate</option>
                    <option value="SeniorPartner">SeniorPartner</option>
                    <option value="ManagingDirector">ManagingDirector</option>
                    <option value="Player">Player</option>
                  </select>
                </div>

                <button onClick={resetFilter} className="rounded-lg px-5">
                  Reset Filter
                </button>
              </div>

              <div className="table-wrapper">
                {isLoading ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : (
                  <>
                    <MemberTable
                      totalPages={data?.data?.totalPages}
                      fetchData={refetch}
                      data={Members}
                      activepagination={activepagination}
                      onPageChange={handlePageChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
