import React, { useState } from "react";
import Pagination from "../../components/admin/Pagination";
import { P2PModal } from "../../components/P2PModal";
import notFound from "../../assets/img/sports/not-found.png"
export const BetTransactionTable = ({
  data,
  // fetchData,
  totalPages,
  onPageChange,
  type
}) => {
  const [activepagination, setactivepagination] = useState(1);

  const handlePageChange = (page) => {
    setactivepagination(page);
    //fetchData(page);
    onPageChange(page);
  };

  const [showModal, setShowModal] = useState(false);
  const [showCurrent, setShowCurrent] = useState('');
  const handleView = (transactionId) => {
    setShowModal(true);
    setShowCurrent(transactionId);
  }

  const handleModalClose = () => {
    setShowModal(false);
    setShowCurrent('');
  };

  return (
    <>
      {showModal && (
        <>
          <P2PModal
            modalType={'table'}
            title={`Match View`}
            text={showCurrent}
            onClose={() => handleModalClose}
          />
        </>
      )}
      {data && data.length === 0 ? (
        <div className="w-full mt-3">
          <img
            src={notFound}
            alt="Not Found"
            className="mx-auto my-0"
          />
          <h1 className="border-t py-3 no-data-available text-center text-white">
            Oops! There is no data yet!
          </h1>
        </div>
      ) : (
        <>
          <table className="w-full AgentTable mt-5">
            <thead>
              <tr>
                <th>Event</th>
                <th>Bet Id</th>
                <th>Bet Amount</th>
                {type && (
                  <th>Odds</th>
                )}
                <th>Status</th>
                {!type && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody className="text-white">
              {data.map((bet) => {
                var betId = `${bet._id}${bet.opponentBetId == null ? '' : `_${bet.opponentBetId}`}`;
                return (
                  <tr key={bet._id}>
                    <td>{bet.bet_team} - {bet.opponent_team}</td>
                    <td>{betId}</td>
                    <td>{bet.bet_amount}</td>
                    {type && (
                      <td>{bet.bet_odds}</td>
                    )}
                    <td className={`text-left px-2 font-arabicreg text-[14px] ${
                                            bet.status === "Loss"
                                                ? "text-red-500"
                                                : bet.status === "Waiting"
                                                ? "text-yellow-500" 
                                                : bet.status === "Refunded" 
                                                ? "text-[#17A2B8]"
                                                : "text-green-500"
                                        }`}>{bet.status === "Win" || bet.status === "Loss" ? "Match Ended" : bet.status}</td>
                    {!type && (
                      <td>
                        <button className="text-white bg-[#17A2B8] rounded-lg p-2 flex" onClick={() => handleView(betId)}>
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3669_4533)">
                            <path d="M7.91656 8.75C8.88306 8.75 9.66656 7.9665 9.66656 7C9.66656 6.0335 8.88306 5.25 7.91656 5.25C6.95007 5.25 6.16656 6.0335 6.16656 7C6.16656 7.9665 6.95007 8.75 7.91656 8.75Z" fill="white"/>
                            <path d="M14.4528 6.85125C13.9382 5.52022 13.0449 4.36914 11.8833 3.54029C10.7216 2.71143 9.34259 2.24113 7.91656 2.1875C6.49054 2.24113 5.11148 2.71143 3.94984 3.54029C2.78819 4.36914 1.89488 5.52022 1.38031 6.85125C1.34556 6.94737 1.34556 7.05263 1.38031 7.14875C1.89488 8.47978 2.78819 9.63086 3.94984 10.4597C5.11148 11.2886 6.49054 11.7589 7.91656 11.8125C9.34259 11.7589 10.7216 11.2886 11.8833 10.4597C13.0449 9.63086 13.9382 8.47978 14.4528 7.14875C14.4876 7.05263 14.4876 6.94737 14.4528 6.85125ZM7.91656 9.84375C7.35412 9.84375 6.80431 9.67697 6.33666 9.36449C5.86901 9.05202 5.50452 8.60788 5.28928 8.08826C5.07404 7.56863 5.01773 6.99685 5.12745 6.44521C5.23718 5.89358 5.50802 5.38687 5.90573 4.98917C6.30343 4.59146 6.81014 4.32062 7.36177 4.21089C7.91341 4.10117 8.48519 4.15748 9.00482 4.37272C9.52445 4.58795 9.96858 4.95245 10.2811 5.4201C10.5935 5.88775 10.7603 6.43756 10.7603 7C10.7592 7.75385 10.4592 8.4765 9.92612 9.00956C9.39306 9.54261 8.67042 9.84259 7.91656 9.84375Z" fill="white"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_3669_4533">
                              <rect width="14" height="14" fill="white" transform="translate(0.916565)"/>
                              </clipPath>
                            </defs>
                          </svg>
                          &nbsp;View
                        </button>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={activepagination}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};
