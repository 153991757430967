/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LobbySports from "./LobbySports";
import { 
  fetchSportsBetGroups, 
  fetchDailyTransaction,
  fetchWeeklyTransaction,
  fetchMonthlyTransaction,
  fetchOverallTransaction, 
} from "../../queries/gaming";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import { SportsBetActivityBox } from "../../components/admin/SportsBetActivityBox";
import { Tooltip } from "@material-tailwind/react";
import info from "../../assets/img/sports/info.png"

const sportImages = [
  "aussie",
  "basketball",
  "soccer",
  "football",
  "volleyball",
  "baseball",
  "golf",
  "cricket",
  "hockey",
  "boxing",
  "mma",
  "rugby",
  "formula",
  "horse",
  "virtual",
];

const getImageForSport = (sport) => {
  try {
    const imageName = sport.toLowerCase();
    const matchingSport = sportImages.find((sport) =>
      imageName.includes(sport)
    );
    if (matchingSport) {
      return require(`../../assets/img/sports/${matchingSport}.svg`);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const SportsBet = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const handleClick = () => {
    setActive((prevActive) => !prevActive);
  };
  const [activeSport, setActiveSport] = useState(1);
  const handleClickActiveSport = (index) => {
    setActiveSport(index);
  };
  const [group, setGroup] = useState([]);
  const [daily, setDaily] = useState({});
  const fetchDaily = async () => {
    try {
      const { data } = await fetchDailyTransaction(active);
      if (data) {
        setDaily(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const [weekly, setWeekly] = useState({});
  const fetchWeekly = async () => {
    try {
      const { data } = await fetchWeeklyTransaction(active);
      if (data) {
        setWeekly(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const [monthly, setMonthly] = useState({});
  const fetchMonthly = async () => {
    try {
      const { data } = await fetchMonthlyTransaction(active);
      if (data) {
        setMonthly(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const [overall, setOverall] = useState({});
  const fetchOverall = async () => {
    try {
      const { data } = await fetchOverallTransaction(active);
      if (data) {
        setOverall(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const fetchGroups = async () => {
    try {
      const { data } = await fetchSportsBetGroups();
      if (data) {
        setGroup(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchDaily();
    fetchWeekly();
    fetchMonthly();
    fetchOverall();
  }, [active]);

  useEffect(() => {
    fetchGroups();
  }, []);

  function addCommasToStringNumber(number) {
    if (number === null || number === undefined) {
      return "0.00";
    }

    const value = parseFloat(number)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  }

  function formatFlags(data) {
    if (!data) {
      return null;
    }

    const formattedData = {
      totalBetAmount: addCommasToStringNumber(data.totalBetAmount),
      totalPayout: addCommasToStringNumber(data.totalPayout),
      totalPlatformFees: addCommasToStringNumber(data.totalPlatformFees),
    };

    return formattedData;
  }

  return (
    <>
    <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
      <Aside active={6} />
      <div className="flex-1">
        <Header heading="Transfer" />

        <div className="body-area-admin py-8 px-6">
          <div className="top-body-area flex items-center justify-between">
            {/* <h1>P2P</h1> */}
            <div className="py-6 w-1/4">
              <div className="flex justify-between gap-1 bg-[#252525] p-3 rounded-full">
                <button
                  className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
                    active
                      ? "bg-[#3A3A3A] w-full"
                      : "bg-transparent w-1/2"
                  }`}
                  onClick={handleClick}
                >
                  Market-based
                </button>
                <button
                  className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
                    active
                      ? "bg-transparent w-1/2"
                      : "bg-[#3A3A3A] w-1/2"
                  }`}
                  onClick={handleClick}
                >
                  P2P
                </button>
              </div>
            </div>
            <button className="flex items-center">
              <img src={arrowback} alt="" className="mr-2" />
              <span
                className="mr-1"
                onClick={(e) => {
                  navigate("/");
                }}
              >
                Home
              </span>
              <p className="text-white"> / Sports Bet</p>
            </button>
          </div>

          <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Statistics</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
              <SportsBetActivityBox
                  title="Daily Sales Report"
                  total={addCommasToStringNumber(daily.totalBetAmount + daily.totalPayout + daily.totalPlatformFees)}
                  type="daily"
                  data={formatFlags(daily)}
                />

                <SportsBetActivityBox
                  title="Weekly Sales Report"
                  total={addCommasToStringNumber(weekly.totalBetAmount + weekly.totalPayout + weekly.totalPlatformFees)}
                  type="weekly"
                  data={formatFlags(weekly)}
                />

                <SportsBetActivityBox
                  title="Monthly Sales Report"
                  total={addCommasToStringNumber(monthly.totalBetAmount + monthly.totalPayout + monthly.totalPlatformFees)}
                  type="monthly"
                  data={formatFlags(monthly)}
                />
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Total Bets Placed</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.totalBetsPlaced}
                  </div>
                </div>
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Total Bet Amount</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.totalBetAmount}
                  </div>
                </div>
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Total Payout</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.totalPayout}
                  </div>
                </div>
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Total Platform Fees</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.totalPlatformFees}
                  </div>
                </div>
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Average Bet Amount</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.averageBetAmount}
                  </div>
                </div>
                <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center box-activity">
                  <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                    <p className="text-white text-[18px] mr-2">Average Payout</p>
                    <div className="inline-flex relative group">
                      <Tooltip
                        content="The total number of bets placed over a specific period."
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      >
                        <img src={info} alt="Info" />
                        </Tooltip>
                    </div>
                    
                  </div>
                  <div className="text-[#6FCF17] font-bold text-[24px]">
                    {overall.averagePayout}
                  </div>
                </div>
              </div>
            </div>

          <div className="p-6 pt-8 rounded-xl">
            {/* <div className="bg-[#1A1A1B] top-agent p-6 rounded-lg top-agent-page flex items-center justify-between">
              <h1>Sports Bet</h1>
            </div> */}
            
            <div className="font-arabicmed w-full">
              <div className="w-full overflow-x-auto">
                <div className="flex gap-2 my-2 whitespace-nowrap">
                  {group.map(
                    (sport, index) =>
                      sport !== "Politics" && (
                        <button
                          key={index}
                          className={`py-3 px-4 bg-[#252525] hover:bg-gradient-to-br hover:from-[#9BE402] hover:to-[#1FA83C] border border-[#3C403F] rounded-lg ${
                            activeSport === index + 1
                              ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                              : ""
                          }`}
                          onClick={() => handleClickActiveSport(index + 1)}
                        >
                          <img
                            src={getImageForSport(
                              sport === "Mixed Martial Arts" ? "MMA" : sport
                            )}
                            className="mx-auto w-6 h-6"
                            alt={sport === "Mixed Martial Arts" ? "MMA" : sport}
                          />
                          <p className="text-[#fff] text-[14px] pt-1">
                            {sport === "Mixed Martial Arts" ? "MMA" : sport}
                          </p>
                        </button>
                      )
                  )}
                </div>
              </div>
              {activeSport && <LobbySports sportName={group[activeSport - 1]} type={active} />}
            </div>
          </div>
        </div>
      </div>
    </div>

      <Footer />
    </>
  );
};
