import React, { useEffect, useState } from "react";
// import { FaXmark } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { fetchTransactionById } from "../queries/gaming";

export const P2PModal = ({
    modalType,
    title,
    text,
    data,
    onClose,
    method,
}) => {
    const [playerOne, setPlayerOne] = useState({});
    const [playerTwo, setPlayerTwo] = useState({});

    useEffect(() => {
      const fetchData = async () => {
          const ids = text.split('_');
          const { data } = await fetchTransactionById(ids[0]);
          setPlayerOne(data);
          if (ids[1]) {
              const { data } = await fetchTransactionById(ids[1]);
              setPlayerTwo(data);
          }
      }

      fetchData();
    }, [text])

    const modalCancellation = () => {
        return (
          <>
            <div className="fixed inset-0  backdrop-blur-md flex items-center justify-center z-40">
              <div className="bg-[#23262E] p-8 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl text-white font-bold">{title}</h2>
                  <button
                    className="text-white hover:text-gray-700"
                    onClick={onClose()}
                  >
                    {/* <FaXmark /> */}
                    <IoClose />
                 
                  </button>
                </div>
                <p className="text-lg py-4 text-white">{text}</p>
                <div className="flex justify-center mt-5">
                  <button
                    className="py-3 mr-5 px-8 bg-gradient-to-b from-[#EA3B1C] to-[#7D0102] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                    onClick={() => method(true)}
                  >
                    YES
                  </button>
                  <button
                    className="py-3 px-5 bg-gradient-to-b from-[#BEBDBD] to-[#4F4E4E] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                    onClick={() => method(false)}
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </>
        );
    }
    const modalTable = () => {
        return (
          <>
            <div className="fixed inset-0  backdrop-blur-md flex items-center justify-center z-40">
              <div className="bg-[#23262E] p-8 rounded-lg w-full ml-5 mr-5">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl text-white font-bold">{title}</h2>
                  <button
                    className="text-white hover:text-gray-700"
                    onClick={onClose()}
                  >
                    {/* <FaXmark /> */}
                    <IoClose />
                  </button>
                </div>
                <div className="bg-[#1A1A1B] p-6 mt-4 rounded-lg w-full">
                  <h1 className="text-white font-arabicbold">Betting Book</h1>
                  <div className="flex md:flex-col">
                    {!playerOne ? (
                      <div className="w-full mt-3">
                        <h1 className="border-t py-3 no-data-available text-center text-white">
                          Oops! There is no data yet!
                        </h1>
                      </div>
                    ) : (
                      <>
                        <div className="w-full overflow-x-auto">
                            <table className="my-4 w-full whitespace-nowrap">
                                <thead>
                                    <tr className="h-[56px] border-b border-[#3A3A3A]">
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Event
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Amount
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Odds
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        User
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Status
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="h-[56px]">
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerOne.bet_team}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerOne.bet_amount}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerOne.bet_odds}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-red-500`}
                                        >
                                        {playerOne.user_id}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] ${
                                            playerOne.status === "Loss"
                                            ? "text-red-500"
                                            : playerOne.status === "Waiting"
                                            ? "text-yellow-500"
                                            : "text-green-500"
                                        }`}
                                        >
                                        {playerOne.status}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="border-r border-white m-4"></div>
                        <div className="w-full overflow-x-auto">
                            <table className="my-4 w-full whitespace-nowrap">
                                <thead>
                                    <tr className="h-[56px] border-b border-[#3A3A3A]">
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Event
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Amount
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Odds
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        User
                                    </th>
                                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                                        Status
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="h-[56px]">
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerOne.opponent_team}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerOne.bet_amount}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                                        >
                                        {playerTwo.bet_odds || playerTwo.bet_odds === 0 ? playerTwo.bet_odds : "-"}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] text-red-500`}
                                        >
                                        {playerTwo.user_id ?? '-'}
                                        </td>
                                        <td
                                        className={`text-left px-2 font-arabicreg text-[14px] ${
                                            playerTwo.status === "Loss"
                                                ? "text-red-500"
                                                : playerTwo.status === "Waiting"
                                                ? "text-yellow-500"
                                                : "text-green-500"
                                        }`}
                                        >
                                        {playerTwo.status ?? '-'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
    return (
        <>
            {modalType.toLowerCase() === "table" && (
                <>{modalTable()}</>
            )}
            {modalType.toLowerCase() === "cancel" && (
                <>{modalCancellation()}</>
            )}
        </>
    )
};
