import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

// Update the useGetAdvertisements hook
export const useGetAdvertisements = (filters) => {
    return useQuery(["advertisements", filters], async () => {
        try {
            const response = await privateRequest.get(`admin/p2p/advertisement/list`, { params: filters });
            return response.data.data;
        } catch (error) {
            throw new Error("Error fetching advertisements data");
        }
    });
};
export const useGeUserAdvertisements = (adsId) => {
    return useQuery(["advertisementsInfo"], async () => {
        try {
            const response = await privateRequest.get(`admin/p2p/advertisement/info/${adsId}`);
            return response.data.data;
        } catch (error) {
            throw new Error("Error fetching advertisements data");
        }
    });
};
export const useGetOrders = (filters) => {
    return useQuery(["orders", filters], async () => {
        try {
            const response = await privateRequest.get(`admin/p2p/order/list`, { params: filters });

            return response.data.data;
        } catch (error) {
            throw new Error("Error fetching orders data");
        }
    });
};
export const useGetOrdersInfo = (orderId) => {
    return useQuery(["orders"], async () => {
        try {
            console.log("Check", orderId);
            const response = await privateRequest.get(`admin/p2p/order/info/${orderId}`);
            return response.data.data;
        } catch (error) {
            throw new Error("Error fetching orders data");
        }
    });
};
export const useGetDisputes = () => {
    return useQuery(["dispute"], async () => {
        try {
            const response = await privateRequest.get(`user/p2p/order/disputedOrders`);
            console.log(response.data);
            return response.data.data;
        } catch (error) {
            throw new Error("Error fetching orders data");
        }
    });
};

export const useOrderDetails = (id) => {
    return useQuery("OrderDetails", async () => {
        const res = await privateRequest.get(`admin/p2p/order/info/${id}`);
        return res?.data;
    });
};