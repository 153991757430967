import React, { useEffect, useState } from "react";
// css
import "../../assets/css/admin/index.css";
import { Link } from 'react-router-dom';
// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrdersInfo } from "../../queries/p2pAds";

export const OrderHistoryView = () => {
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const [paymentDropdownVisible, setPaymentDropdownVisible] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  console.log(params.id);
  const { isLoading, isError, data, error } = useGetOrdersInfo(params.id);

  // Function to toggle visibility of user dropdown
  const toggleUserDropdown = () => {
    setUserDropdownVisible(!userDropdownVisible);
  };

  // Function to toggle visibility of payment methods dropdown
  const togglePaymentDropdown = () => {
    setPaymentDropdownVisible(!paymentDropdownVisible);
  };

  return (
    <>
      <div>
        <div className="flex items-start home admin-dashboard admin-dashboard-agent">
          <Aside active="8/2" />
          <div className="flex-1">
            <Header heading="History View" />
            <div className="body-area-admin py-8 px-6">
              <div className="top-body-area flex items-center justify-between">
                <h1>Order Details</h1>
                <button className="flex items-center" onClick={() => navigate(-1)}>
                  <img src={arrowback} alt="" className="mr-2" />
                  <Link to={`/admin/p2p/order/history`}>
                    <h1 className="font-arabicreg text-[16px] text-lg text-[#11E311]">Back</h1>
                  </Link>
                </button>
              </div>
              <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">

                <div className="text-white space-y-8">
                  {isLoading && <div>Loading...</div>}
                  {!isLoading && data && (
                    <div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1 flex-col justify-start sm:flex-row sm:justify-between border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Created time: </div>
                          <div className="font-arabicreg">
                            {data?.createdAt}
                          </div>
                        </div>

                        <div className="flex flex-1 flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Order ID: </div>
                          <div className="font-arabicreg">
                            {data?._id}
                          </div>
                        </div>
                      </div>
                      <div className="flex sm:flex-col">

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Sender email</div>
                          <div className="font-arabicreg">
                            {data.sender.email}
                          </div>
                        </div>

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Receiver email</div>
                          <div className="font-arabicreg">
                            {data.receiver.email}
                          </div>
                        </div>

                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Ads Type </div>
                          <div className="font-arabicreg">
                            {data.adsType === "to_buy" ? "BUY" : "SELL"}
                          </div>
                        </div>

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Price Type: </div>
                          <div className="font-arabicreg">
                            {data.status}
                          </div>

                        </div>
                      </div>

                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Fiat Currency</div>
                          <div className="font-arabicreg ">
                            {data.advertisement.cryptocurrency}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Crypto Currency</div>
                          <div className="font-arabicreg ">
                            {data.advertisement.fiatCurrency}
                          </div>
                        </div>
                      </div>


                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Amount</div>
                          <div className="font-arabicreg ">
                            {data.amount}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Fiat Amount</div>
                          <div className="font-arabicreg ">
                            {data.fiatAmount}
                          </div>

                        </div>
                      </div>
                      <div className="flex sm:flex-col">

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Exchange Rate</div>
                          <div className="font-arabicreg ">
                            {data.exchangeRate} {data.fiatCurrency}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Receivable Amount</div>
                          <div className="font-arabicreg ">
                            {data.receivableAmount}
                          </div>

                        </div>
                      </div>


                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Net Fee</div>
                          <div className="font-arabicreg cursor-pointer" >
                            {data.netFee}
                          </div>
                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Flag</div>
                          <div>
                            <div className="font-arabicreg ">
                              {data.flag}
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Terms:</div>
                          <div className="font-arabicreg cursor-pointer" onClick={togglePaymentDropdown}>
                            {paymentDropdownVisible ? (
                              <div>
                                {data.paymentMethod.map((method, index) => (
                                  <div key={method._id}>{method.name}</div>
                                ))}
                              </div>
                            ) : (
                              <div>Click to expand</div>
                            )}
                            {/* {data && data.paymentMethods ? (
                              data.paymentMethods.map((method) => (
                                <div key={method._id}>{method.name}</div>
                              ))
                            ) : (
                              <div>Loading payment methods...</div>
                            )} */}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Remarks:</div>
                          <div className="font-arabicreg ">
                            {data.remarks > 0 ? data.remarks : "no remarks"}
                          </div>

                        </div>
                      </div>
                    </div>
                  )}
                  {/* {!isLoading && data && (
                    <table className="table-auto w-full border-collapse border border-white">
                      <tbody>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Order ID</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data._id}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Created At</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.createdAt}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Status</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.status}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Amount</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.amount}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Fiat Amount</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.fiatAmount}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Net Fee</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.netFee}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Flag</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.flag}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Remarks</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.remarks}</td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Ads Type</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.advertisement.adsType}</td>
                        </tr>
                        
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Exchange Rate</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center">{data.advertisement.exchangeRate}</td>
                        </tr>
                        
                        <tr className="border-b border-white">
                          <td className="text-lg font-bold py-2 px-4 border-r border-white text-left">Payment Methods</td>
                          <td className="text-lg py-2 px-4 border-r border-white text-center cursor-pointer" onClick={togglePaymentDropdown}>
                            {paymentDropdownVisible ? (
                              <div>
                                {data.paymentMethod.map((method, index) => (
                                  <div key={method._id}>{method.name}</div>
                                ))}
                              </div>
                            ) : (
                              <div>Click to expand</div>
                            )}
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
