import React, { useEffect, useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import csv from "../../assets/img/admin/svg/csv.svg";
import search from "../../assets/img/admin/svg/search.svg";

// utils
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";
import { AdvertisementTable } from "./AdvertisementTable";
import { useGetAdvertisements } from "../../queries/p2pAds";

export const AdvertisementHistory = () => {
  const [value, onChange] = useState(new Date());
  const [calenderState, setCalender] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [AdsTypeValue, setAdsType] = useState("");
  const [CurrencyValue, setCurrency] = useState("");
  const [DateValue, setDateValue] = useState("");
  const [PeriodValue, setPeriod] = useState("");
  const [sizeValue, setsize] = useState("");
  const [activepagination, setActivePagination] = useState(1);

  const navigate = useNavigate();

  // console.log("DATE AND TIME: ", DateValue);
  const [filters, setFilters] = useState({
    _id: "",
    adsType: "",
    flag: "",
    startDate: "",
    endDate: "",
    Size: "",
  });
  const { isLoading, refetch, data } = useGetAdvertisements(filters);
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSearchFetch = (e) => {
    const inputValue = e.target.value;

    setFilters((prevFilters) => ({ ...prevFilters, _id: e.target.value }));
    setSearchInput(e.target.value);
    if (inputValue.length === 24) {

      refetch();
    }
  };
  const handlePasteFetch = (e) => {
    const pastedValue = e.clipboardData.getData('text');

    setSearchInput(e.target.value);
    if (pastedValue.length === 24) {
      refetch();
    }
  };
  const handleFilterChange = (field, e) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: e }));
  };
  const handleCalenderChange = (field, dateValue) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: dateValue }));
  };
  const handleResetFilter = () => {
    setFilters({
      _id: "",
      adsType: "",
      flag: "",
      startDate: "",
      endDate: "",
      Size: "",
    });
  };

  const handlePaginationClick = (pageNumber) => {
    setActivePagination(pageNumber);

    setFilters((prevFilters) => ({ ...prevFilters, page: pageNumber }));
  };
  const advertisementsDataList = data;
  const uniqueFiatCurrencies = advertisementsDataList ?
    [...new Set(advertisementsDataList.docs.map(advertisement => advertisement.fiatCurrency))] :
    [];
  const uniqueCryptoCurrencies = advertisementsDataList ?
    [...new Set(advertisementsDataList.docs.map(advertisement => advertisement.cryptoCurrency))] :
    [];
  const uniqueCurrencies = [...new Set([...uniqueFiatCurrencies, ...uniqueCryptoCurrencies])];
  // console.log(advertisementsDataList);
  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside active={6} />

        <div className="flex-1">
          <Header heading="Transfer" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>P2P</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>
                <p className="text-white"> / Advertisement</p>
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Advertisement</h1>
              </div>
              <div className="bottom-agent bottom-agent-page pt-6 pb-4 gap-2 flex items-end">
                <div className="input-container w-60">
                  <label htmlFor="email" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      value={searchInput}
                      onChange={handleSearchFetch}
                      onKeyDown={handleSearchFetch}
                      onPaste={handlePasteFetch}
                      className="flex-1 w-full h-full bg-transparent border-0 outline-none ml-2"
                    />
                  </div>
                </div>

                <div className="input-container w-40">
                  <label htmlFor="flag" className="mb-3 block">
                    Ads Type
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="adsType"
                      id="adsType"
                      onChange={(e) => {
                        // console.log(e); // Debugging statement
                        handleFilterChange("adsType", e.target.value);
                      }}
                    >
                      <option value="to_buy">Buy</option>
                      <option value="for_sell">Sell</option>
                    </select>
                  </div>


                </div>

                <div className="input-container w-40">
                  <label htmlFor="currency" className="mb-3 block">
                    Currency
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="currency"
                      id="currency"
                      value={filters.currency}
                      onChange={(e) =>
                        handleFilterChange("cryptoCurrency", e.target.value)
                      }
                    >
                      {/* Dynamically generate options */}
                      {uniqueCurrencies.map(currency => (
                        <option key={currency} value={currency}>{currency}</option>
                      ))}
                    </select>
                  </div>
                </div>


                <div className="input-container relative w-56 flex-1">
                  <label htmlFor="timePeriod" className="mb-3 block">
                    Time Period
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    {calenderState && (
                      <Calendar
                        onChange={(e) => {

                          onChange(e);

                          let FirstStart = String(e[0]);
                          let SecondStart = String(e[1]);

                          let FirstcorrectDay = `${FirstStart.split(" ")[2]}/${FirstStart.split(" ")[1]
                            }/${FirstStart.split(" ")[3]}`;

                          let SecondcorrectDay = `${SecondStart.split(" ")[2]
                            }/${SecondStart.split(" ")[1]}/${SecondStart.split(" ")[3]
                            }`;
                          // console.log(FirstcorrectDay);
                          // console.log(SecondcorrectDay);
                          setDateValue(
                            `${FirstcorrectDay} to ${SecondcorrectDay}`
                          );
                          handleCalenderChange("startDate", FirstcorrectDay)
                          handleCalenderChange("endDate", SecondcorrectDay)
                          setCalender(false);
                        }}
                        value={value}
                        selectRange={true}
                      />
                    )}

                    <input
                      onClick={(e) => {
                        setCalender(!calenderState);
                      }}
                      type="text"
                      placeholder="2023/09/19 to 2024/12/01"
                      value={DateValue}
                      // value={filters.startDate}
                      // onChange={(e) =>
                      //   handleFilterChange("startDate", e.target.value)
                      // }
                      className="flex-1 w-full h-full bg-transparent border-0 outline-none cursor-pointer"
                      readOnly
                    />
                  </div>
                </div>

                <div className="input-container w-32">
                  <label htmlFor="status" className="mb-3 block">
                    Size
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="status"
                      id="status"
                      value={filters.status}
                      onChange={(e) =>
                        handleFilterChange("status", e.target.value)
                      }
                    >
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>

                <button className="rounded-lg px-5" onClick={handleResetFilter}>
                  Reset Filter
                </button>
              </div>

              <div className="table-wrapper">
                <AdvertisementTable advertisementsDataList={advertisementsDataList}
                  onPageChange={handlePaginationClick}
                  pageNumber
                  filters
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
