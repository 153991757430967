//gaming.jsx
import { privateRequest } from "../config/axios.config";

export const fetchSports = async () => {
  try {
    const res = await privateRequest.post("bc-game/sports-game");
    const embed_code = res.data.data.embed_code;
    return embed_code;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports games"
    );
  }
};

export const fetchSportsBetGroups = async () => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-groups");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet groups"
    );
  }
};
export const fetchSportsBetTransactions = async (keys, type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-transactions", { keys, type });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet groups"
    );
  }
};
export const postFavouriteSport = async (sportName, status) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-favourites", {
      sportName,
      status,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ??
        "Error posting favourite sports bet groups"
    );
  }
};
export const postSportsBet = async (betData) => {
  try {
    const response = await privateRequest.post("bc-game/post-sportsbet", {
      betData,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error saving sports P2P bet"
    );
  }
};
export const refundSportsBet = async (transaction_id) => {
  try {
    const response = await privateRequest.post("bc-game/refund-sportsbet", {
      transaction_id,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error refunding sports P2P bet"
    );
  }
};
export const fetchSportsBet = async () => {
  try {
    const response = await privateRequest.post("bc-game/get-sportsbet");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching P2P sports bet"
    );
  }
};
export const fetchMySportsBet = async () => {
  try {
    const response = await privateRequest.post("bc-game/get-mybets");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching My P2P sports bet"
    );
  }
};
export const fetchAllSportsBet = async () => {
  try {
    const response = await privateRequest.get("bc-game/get-allsportsbet");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching All P2P sports bet"
    );
  }
};
export const fetchSportsBetHighRollers = async () => {
  try {
    const response = await privateRequest.get(
      "bc-game/get-sportsbet-highrollers"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ??
        "Error fetching P2P Sports Bet High Rollers"
    );
  }
};
export const fetchSportsBetLeaderboards = async () => {
  try {
    const response = await privateRequest.get(
      "bc-game/get-sportsbet-leaderboards"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ??
        "Error fetching P2P Sports Bet Leaderboards"
    );
  }
};
export const fetchSportsBetCategory = async (sportName) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-category", {
      sportName,
      userType: "admin"
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet category"
    );
  }
};
export const fetchSportsEvents = async (keys, tab) => {
  try {
    const response = await privateRequest.post("bc-game/sport-events", {
      keys,
      tab,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports events"
    );
  }
};
export const fetchTransactionById = async (id, type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-transactionId", {
      id, 
      type
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports transaction"
    );
  }
};
export const fetchDailyTransaction = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-daily-transactions", {
      type
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching daily sports bet transaction"
    );
  }
};
export const fetchWeeklyTransaction = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-weekly-transactions", {
      type
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching weekly sports bet transaction"
    );
  }
};
export const fetchMonthlyTransaction = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-monthly-transactions", {
      type
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching monthly sports bet transaction"
    );
  }
};
export const fetchOverallTransaction = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-overall-transactions", {
      type
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching overall sports bet transaction"
    );
  }
};
