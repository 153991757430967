import React, { useEffect, useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "../../components/admin/Alert";
import { privateRequest } from "../../config/axios.config";
import { useGeUserAdvertisements } from "../../queries/p2pAds";
export const AdvertisementHistoryView = () => {
  const [transactionId, setTransactionId] = useState(null);
  const [delpopup, setdelpopup] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [dropDown, setdropDown] = useState("Click to expand");
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const [paymentDropdownVisible, setPaymentDropdownVisible] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  // console.log("id", params.id);
  const { isLoading, isError, data, error } = useGeUserAdvertisements(params.id);

  // Function to toggle visibility of user dropdown
  const toggleUserDropdown = () => {
    setUserDropdownVisible(!userDropdownVisible);
    setDropdownText();
  };
  const setDropdownText = () => {
    setdropDown("");
  }
  // Function to toggle visibility of payment methods dropdown
  const togglePaymentDropdown = () => {
    setPaymentDropdownVisible(!paymentDropdownVisible);
  };
  // if (data.user && typeof data.user === 'object') {
  //   const userKeys = Object.keys(data.user);
  //   console.log("Number of properties in user object:", userKeys.length);
  // } else {
  //   console.log("user is not an object or is undefined");
  // }
  return (
    <>
      <div>
        <div className="flex items-start home admin-dashboard admin-dashboard-agent">
          <Aside active="8/2" />
          <div className="flex-1">
            <Header heading="History View" />
            <div className="body-area-admin py-8 px-6">
              <div className="top-body-area flex items-center justify-between">
                <h1>Advertisement Details</h1>
                <Link
                  to="/admin/p2p/advertisement/history"
                  className="flex items-center text-[#6FCF17]">
                  <img src={arrowback} alt="" className="mr-2" />
                  Back
                </Link>
              </div>
              <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
                {/* <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                  <h1>Advertisement Details</h1>
                </div> */}
                <div className="text-white  space-y-8">
                  {isLoading && <div>Loading...</div>}
                  {data && (
                    <div>
                      {/* {renderFlattenedObject(flattenObject(data))} */}
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1 flex-col justify-start sm:flex-row sm:justify-between border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Created time: </div>
                          <div className="font-arabicreg">
                            {data?.createdAt}
                          </div>
                        </div>

                        <div className="flex flex-1 flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Ads ID: </div>
                          <div className="font-arabicreg">
                            {data?._id}
                          </div>
                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">User email</div>
                          <div className="font-arabicreg">
                            {data.user?.email}
                          </div>
                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">User Status</div>
                          <div className="font-arabicreg">
                            {data?.status}
                          </div>
                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Ads Type </div>
                          <div className="font-arabicreg">
                            {data.adsType === "to_buy" ? "BUY" : "SELL"}
                          </div>
                        </div>

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Price Type: </div>
                          <div className="font-arabicreg">
                            {data.exchangeRateType}
                          </div>

                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Crypto Currency</div>
                          <div className="font-arabicreg ">
                            {data.cryptoCurrency}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Fiat Currency:</div>
                          <div className="font-arabicreg ">
                            {data.fiatCurrency}
                          </div>

                        </div>
                      </div>
                      <div className="flex sm:flex-col">

                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Exchange Rate</div>
                          <div className="font-arabicreg ">
                            {data.exchangeRate} {data.fiatCurrency}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Price Markup Percentage</div>
                          <div className="font-arabicreg ">
                            {data.priceMarkupPercentage} %
                          </div>

                        </div>
                      </div>

                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Order Limit</div>
                          <div className="font-arabicreg ">
                            {data.min} {data.fiatCurrency} - {data.max} {data.fiatCurrency}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Total Supply</div>
                          <div className="font-arabicreg ">
                            {data.totalSupply} {data.fiatCurrency}
                          </div>
                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Payment Method</div>
                          <div>
                            <div className="font-arabicreg ">
                              {data.paymentMethods.map((method) => (
                                <div key={method._id}>{method.name}</div>
                              ))}
                            </div>
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Payment Time Limit</div>
                          <div>
                            <div className="font-arabicreg ">
                              {data.timeWindowInMinute} min
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="flex sm:flex-col">
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Terms:</div>
                          <div className="font-arabicreg ">
                            {data.advertiserTerms > 0 ? data.advertiserTerms : "No terms"}
                          </div>

                        </div>
                        <div className="flex flex-1  flex-col justify-start sm:flex-row sm:justify-between b border-b gap-2 border-gray-900 py-4">
                          <div className="text-[#AEB2B1]">Remarks:</div>
                          <div className="font-arabicreg ">
                            {data.remarks > 0 ? data.remarks : "no remarks"}
                          </div>

                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
